import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { LoginByEmailMutation, LoginByEmailMutationVariables } from './loginByEmail.graphql';

const QUERY = gql`
	mutation LoginByEmail($input: LoginByEmailMutationInput!) {
		loginByEmail(input: $input) {
			status
			waitTime
		}
	}
`;

export type LoginByEmailMutationInput = LoginByEmailMutationVariables['input'];
export type LoginMutationResponse = LoginByEmailMutation['loginByEmail'];

export async function loginByEmail(
	input: LoginByEmailMutationInput,
	{ gql: { fetch } }: UtilityContext,
): Promise<LoginMutationResponse> {
	const { data, errors } = await fetch<LoginByEmailMutation, LoginByEmailMutationVariables>({
		path: '/login',
		query: QUERY,
		variables: {
			input,
		},
		important: true, // to ensure this rate limited mutation is not batched
	});

	if (errors) throw new Error(JSON.stringify(errors));
	if (!data || !data.loginByEmail) throw new Error('No data returned from query.');
	return data.loginByEmail;
}
