import { useCallback, useMemo, useState } from 'react';
import type { JSX } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import { isSsr } from '@change-corgi/core/ssr';

import { useLoginOrSignupPageFcm, useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';
import {
	initializeLoginSignupProps,
	useGotoRedirect,
	useLoginOrSignupState,
	useLoginOrSignupTracking,
	useLoginUserFlow,
} from 'src/app/pages/loginOrSignup/shared/hooks';
import { GoogleAuthButton } from 'src/app/shared/components/buttons';
import type { GoogleLoginResultData } from 'src/app/shared/hooks/auth';

export function GoogleButton(): JSX.Element {
	const context = useUtilityContext();
	const { translate } = useI18n();
	const [trackedError, setTrackedError] = useState(false);
	const [, { setError }] = useLoginOrSignupPageState();
	const { setNewGDPRUserCreatedViaSocialAuth, gotoStep } = useLoginOrSignupState();
	const { trackLoginError } = useLoginOrSignupTracking();
	const userFlow = useLoginUserFlow();
	const optionalOnClickTrackProps = useMemo(
		() => ({
			// facebook is not available server-side
			...(!isSsr() ? initializeLoginSignupProps(context.facebook) : undefined),
			user_flow: userFlow,
		}),
		[context.facebook, userFlow],
	);
	const gotoRedirectTo = useGotoRedirect();
	const { futureSapMarketingConsentRequired: isFutureSapMarketingConsentRequired } = useLoginOrSignupPageFcm();

	const onSuccess = useCallback(
		(data: GoogleLoginResultData) => {
			if (!data.existingUser && isFutureSapMarketingConsentRequired) {
				setNewGDPRUserCreatedViaSocialAuth(true);
				gotoStep('marketing_consent');
			} else {
				gotoRedirectTo({ loginOrJoinMethod: 'manual_google' });
			}
		},
		[gotoStep, gotoRedirectTo, isFutureSapMarketingConsentRequired, setNewGDPRUserCreatedViaSocialAuth],
	);

	const onError = useCallback(() => {
		if (!trackedError) {
			trackLoginError('manual_google', 'unknown');
			setTrackedError(true);
		}
		setError(translate('corgi.login_or_signup.error.google.generic'));
	}, [trackedError, setError, translate, trackLoginError]);

	return (
		<GoogleAuthButton
			onSuccess={onSuccess}
			onError={onError}
			optionalOnClickTrackProps={optionalOnClickTrackProps}
			loginType={userFlow}
			signupContext="login_or_signup_social_auth"
		/>
	);
}
