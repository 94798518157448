import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import type { AuthMethod } from '@change-corgi/middle-end-gql-schema';

import type { UserAuthMethodQuery, UserAuthMethodQueryVariables } from './getEmailAuthMethod.graphql';

export async function getEmailAuthMethod(email: string, { gql: { fetch } }: UtilityContext): Promise<AuthMethod> {
	const { data, errors } = await fetch<UserAuthMethodQuery, UserAuthMethodQueryVariables>({
		query: gql`
			query UserAuthMethod($email: String!) {
				authMethod: userAuthMethodByEmail(email: $email)
			}
		`,
		variables: { email },
		path: '/userAuthMethodByEmail',
		important: true, // to ensure this rate limited mutation is not batched
	});

	if (errors) throw new Error(JSON.stringify(errors));
	if (!data) throw new Error('No data returned from query.');
	return data.authMethod;
}
