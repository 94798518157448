import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type {
	SendResetPasswordLinkMutation,
	SendResetPasswordLinkMutationVariables,
} from './sendResetPasswordLink.graphql';

export type SendResetPasswordLinkInput = SendResetPasswordLinkMutationVariables;

export async function sendResetPasswordLink(
	input: SendResetPasswordLinkMutationVariables,
	{ gql: { fetch } }: UtilityContext,
): Promise<boolean> {
	const { data, errors } = await fetch<SendResetPasswordLinkMutation, SendResetPasswordLinkMutationVariables>({
		query: gql`
			mutation SendResetPasswordLink($email: String!) {
				sendResetPasswordLink(input: { email: $email }) {
					success
				}
			}
		`,
		variables: input,
	});

	if (errors) throw new Error(JSON.stringify(errors));

	return !!data?.sendResetPasswordLink?.success;
}
