import type { PrefetchContext } from 'src/shared/prefetch';

import type { LoginOrSignupPageFcmState } from './pageFcm';
import { prefetchLoginOrSignupPageFcm, useAsyncLoginOrSignupPageFcm } from './pageFcm';

export type LoginOrSignupPageState = LoginOrSignupPageFcmState;

export function usePageContext(): LoginOrSignupPageState {
	return useAsyncLoginOrSignupPageFcm();
}

export async function prefetchPageContext(context: PrefetchContext): Promise<void> {
	await prefetchLoginOrSignupPageFcm(context);
}
