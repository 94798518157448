import { useCallback, useMemo, useState } from 'react';
import type { JSX } from 'react';

import { Translate, useI18n } from '@change-corgi/core/react/i18n';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import { isSsr } from '@change-corgi/core/ssr';

import { useLoginOrSignupPageFcm, useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';
import {
	initializeLoginSignupProps,
	useGotoRedirect,
	useLoginOrSignupState,
	useLoginOrSignupTracking,
	useLoginUserFlow,
} from 'src/app/pages/loginOrSignup/shared/hooks';
import { FacebookAuthButton } from 'src/app/shared/components/buttons';
import type { FacebookLoginResultData } from 'src/app/shared/hooks/auth';
import { useFacebookProfile } from 'src/app/shared/hooks/social';

export function FacebookButton(): JSX.Element {
	const context = useUtilityContext();
	const { translate } = useI18n();
	const { loadFacebookProfile } = useFacebookProfile();
	const [, { setError }] = useLoginOrSignupPageState();
	const { setNewGDPRUserCreatedViaSocialAuth, gotoStep } = useLoginOrSignupState();
	const { trackLoginError } = useLoginOrSignupTracking();
	const [trackedError, setTrackedError] = useState(false);
	const userFlow = useLoginUserFlow();
	const optionalOnClickTrackProps = useMemo(
		() => ({
			// facebook is not available server-side
			...(!isSsr() ? initializeLoginSignupProps(context.facebook) : undefined),
			context: 'login',
			screen: 'login_initial_view',
			user_flow: userFlow,
		}),
		[context.facebook, userFlow],
	);
	const gotoRedirectTo = useGotoRedirect();
	const { futureSapMarketingConsentRequired: isFutureSapMarketingConsentRequired } = useLoginOrSignupPageFcm();

	const handleSuccess = useCallback(
		(data: FacebookLoginResultData) => {
			if (!data.existingUser) {
				loadFacebookProfile();
			}
			if (!data.existingUser && isFutureSapMarketingConsentRequired) {
				setNewGDPRUserCreatedViaSocialAuth(true);
				gotoStep('marketing_consent');
			} else {
				gotoRedirectTo({ loginOrJoinMethod: 'manual_fb' });
			}
		},
		[
			gotoStep,
			gotoRedirectTo,
			isFutureSapMarketingConsentRequired,
			loadFacebookProfile,
			setNewGDPRUserCreatedViaSocialAuth,
		],
	);
	const onError = useCallback(() => {
		if (!trackedError) {
			trackLoginError('manual_fb', context.facebook.authStatus ?? 'unknown');
			setTrackedError(true);
		}
		setError(translate('corgi.login_or_signup.error.facebook.generic'));
	}, [trackedError, trackLoginError, context.facebook, setError, translate]);

	return (
		<FacebookAuthButton
			onSuccess={handleSuccess}
			onError={onError}
			optionalOnClickTrackProps={optionalOnClickTrackProps}
			loginType={userFlow}
		>
			<Translate value="corgi.facebook.continue_with" />
		</FacebookAuthButton>
	);
}
